import React from 'react'
import SeparatorAbsSvg2 from '../shaping/SeparatorAbsSvg2'
import useTheme from '@mui/material/styles/useTheme'
// const SeparatorAbsSvg2 = lazy(() => import(/* webpackMode:"lazy", webpackChunkName: "SeparatorAbsSvg2Lazy" */ "../shaping/SeparatorAbsSvg2"))


const SepNormalRelDblFullHorizontal1 = () => {

    const theme = useTheme();

    const optionsColorsSeparatorAbs = {
        gradientBgColor: theme.palette.secondary2.main,
        fillColors: [ theme.palette.primary2.main, theme.palette.primary2.dark, theme.palette.primary2.main ],
        strokeColor: theme.palette.primary2.contrastText,
    }

    return (
        <SeparatorAbsSvg2
            name="sepNormalRelDblFullHorizontal1"
            optionsSep={{
                ...optionsColorsSeparatorAbs,
                dPathOrderNotIn: [ 1 ],
                // heightsWanted: [ 8, 16, 32, 48, 64 ],
                heightsWanted: 25, // [ 8, 16, 32, 48, 64 ],
                paddingYSep: 0,
                svgYPadding: 0,
                // isQuatro: true,
                // isDouble: true,
                countUnits: 1,
                countSvg: 1,
                // styleWrapSep: {
                //     marginTop: "5px",
                //     marginBottom: "5px",
                // }
            }}
            key="sepAboveFullDown"
        />
    )
}

export default SepNormalRelDblFullHorizontal1